export class ServiceConstant {
  public static serviceGetUserDetail = "/getUserDetail"
  public static serviceRetrieveToken = "/oauth/token"
  public static serviceLogout = "/revoke"
  public static serviceForgetPassword = "/user/reset"
  public static serviceChangPassword = "/user/changePass"
  //operation
  public static serviceSearchReport = "/searchReport"
  public static serviceSearchComplaint = "/searchComplaint"

  // Operation Service
  public static servicePostRequest = "/request"
  public static serviceSaveTransaction = "/saveTransaction"
  public static serviceGetTransactionDetail = "/getTransactionDetail"
  public static serviceGetTransactionAll = "/getTransactionAll"
  public static serviceUpdateTransaction = "/updateTransaction"
  public static serviceCancelTransaction = "/cancelTransaction"
  public static serviceApproveTransaction = "/approveTransaction"
  public static serviceUpdateRequest = "/updateRequest"
  public static serviceGetTransactionAllByItem = "/getTransactionAllByItem"
  
  //usermanagement
  public static serviceGetRole = "/getRole"
  public static serviceGetUserByRole = "/getUserFindByRole"
  public static serviceUpdateUser = "/updateUser"
  public static serviceGetUserByCompany = "/getUserByCompany"
  public static serviceGetUserByCollector= "/getUserByCollector"
  public static serviceGetUserFindByUsername2= "/getUserFindByUsername2"
  public static serviceGetWTCollector = '/getWTCollector'

  //masterdate
  public static serviceGetApplicationConfig = "/getApplicationConfig"
  public static serviceUpdateConfig = "/updateConfig"
  public static serviceGetwasteType = "/getWasteType"
  public static serviceGetcompany = "/getCompany"
  public static serviceGetunit = "/getUnit"
  public static serviceGetRequestType = "/getRequestType"
  public static serviceGetProvince = "/getProvince"
  public static serviceGetDistrict = "/getDistrict"
  public static serviceGetSubDistrict = "/getSubDistrict"
  public static serviceSearchCompany = "/searchCompany"
  public static serviceGetAddress = "/getAddress"
  public static serviceGetCompanytype = "/getCompanyType"
  public static serviceUpdateCompany = "/updateCompany"
  public static serviceInsertCompany = "/insertCompany"
  public static serviceGetCollector = "/getCollector"
  public static serviceUpdateCollector = "/updateCollector"
  public static serviceInsertCollector = "/insertCollector"
  public static serviceGetfeeService = "/getFeeService"
  public static serviceGetUnitConvertionDefault = "/getUnitConvertionDefault"
  public static serviceGetUnitConvertion = "/getUnitConvertion"
  public static serviceGetMunicipality= "/getMunicipality"
  public static serviceUpdateUnit= "/updateUnit"
  public static serviceUpdateFee= "/updateFeeService"
  public static serviceUpdateWasteType= "/updateWasteType"
  public static serviceInsertUnit= "/insertUnit"
  public static serviceInsertFee= "/insertFeeService"
  public static serviceInsertWasteType= "/insertWasteType"
  public static serviceGetAllcompany = "/getAllCompany"
  
  //report
  public static serviceGetReportManifest = "/getReportManifest"
  public static serviceGetReportAccounting = "/getReportAccounting"
  public static serviceGetReportSummary = "/getReportSummary"
  public static serviceGetReportRequest = "/getReportRequest"
  public static serviceGetSumaryDashboradByDate = "/getSumaryDashboradByDate"
  public static serviceGetSumaryDashboradByMonth = "/getSumaryDashboradByMonth"
  public static serviceGetSumaryDashboradByYear = "/getSumaryDashboradByYear"
  public static serviceGetSlideNews = "/getSlideNews"
  public static serviceGetNews = "/getNews"
  public static serviceUpdateSlideNews= "/updateSlideNews"
  public static serviceUpdateNews = "/updateNews"
  public static serviceInsertSlideNews = "/insertSlideNews"
  public static serviceInsertNews = "/insertNews"
  public static serviceGetGraphTrans = "/getGraphTrans"
  public static serviceGetReportDashboard = "/getReportDashboard"
}

Object.freeze(ServiceConstant);
