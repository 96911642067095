import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'trimString2' })
export class trimString2 implements PipeTransform {
  /**
	 * Transform
	 *
	 * @param value: any
	 * @param args: any
	 */
	public storage = localStorage;
	transform(value: any, args?: any): any {

		if(this.storage['language'] == 'th'){
			var index = value.indexOf( ' ', value.indexOf( ' ' ) + 1 );
			var firstChunk = value.substr( 0, index );
			var index2 = firstChunk.indexOf( ' ', firstChunk.indexOf( ' ' )  );
			var firstChunk2 = firstChunk.substr( index2 +1 );
			return firstChunk2;
		}
		else
		{
			var index = value.indexOf( ' ', value.indexOf( ' ' )  );
			var firstChunk = value.substr( 0, index ) == "" ? value : value.substr( 0, index );
			var index2 = firstChunk.indexOf( ' ', firstChunk.indexOf( ' ' )  );
			var firstChunk2 = firstChunk.substr( index2 +1 );
			return firstChunk2;

		}
	}
}