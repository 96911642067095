// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
import { Router,ActivatedRoute } from '@angular/router';
import {AppProfileService} from '../../../../../core/_base/layout/services/appprofile.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'; 
import { AuthorizationService } from '../../../../../core/_base/layout/services/authorization.service';
@Component({
	selector: 'kt-user-profile2',
	templateUrl: './user-profile2.component.html',
})
export class UserProfile2Component implements OnInit {
	// Public properties
	user$: Observable<any>;
	public fullname;
	public companyDetail;
	public collector;
	public pic;
	public storage = localStorage;
	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		public appProfileService : AppProfileService,
        private authService: AuthorizationService,
		private router: Router,
		private sanitizer: DomSanitizer,) {
	}
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.fullname = this.appProfileService.userProfile.information.first_name+" "+this.appProfileService.userProfile.information.last_name
		if(this.appProfileService.role != 'AFS' &&  this.appProfileService.role != 'ADMIN'){
			if(this.appProfileService.userProfile.company){
				this.authService.getcompany({"companycode": this.appProfileService.userProfile.company})
				.subscribe(data => {
					if(data.length > 0){
						this.companyDetail = data[0];
					}
				});
			}
			if(this.appProfileService.userProfile.wtdetail && this.appProfileService.userProfile.wtdetail.collector){
				this.authService.getCollector({"collector": this.appProfileService.userProfile.wtdetail.collector})
				.subscribe(data => {
					if(data.length > 0){
						this.collector = data[0];
					}
				});
			}
		}
		
	}
	getPhoImage(){
        if(this.appProfileService.userProfile.photo.image){
		  return this.appProfileService.userProfile.photo.image;
			
        }else{
          return "https://ssl.gstatic.com/accounts/ui/avatar_2x.png"
        }
    
      }
	/**
	 * Log out
	 */
	seeProfile(){
	}
	logout() {
		// this.store.dispatch(new Logout());
		this.authService.logout()
	}

	goProfile(){
		this.router.navigate(['/masterUsermanagement/edit'], { queryParams: {username : this.appProfileService.userProfile.username}});
	}
}
