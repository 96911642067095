import { Injectable } from '@angular/core';
import { Http } from '../http/http';
@Injectable()
export class CommonService {

  constructor(
    private http: Http,
  ) { }


  public pathParam(url, query) {
    let pathParam = '';
    if (query) {
      pathParam = '?';
      for (let key in query) {
        if (query[key] || query[key] === 0) {
          pathParam += encodeURIComponent(key) + '=' + encodeURIComponent(query[key]) + '&';
        }
      }
    }
    return url + pathParam;
  }


  public retrieveToken(user,pass, url) {
    return this.http.retrieveToken(user,pass, url);
  }

  public getAuth(url, query) {
    return this.http.commonHttpMethod('auth', this.pathParam(url, query), null);
  }

  public get(url, data) {
    return this.http.commonHttpMethod('get', this.pathParam(url, data), null);
  }

  public post(url, data) {
    return this.http.commonHttpMethod('post', url, data);
  }

  public put(url, data) {
    return this.http.commonHttpMethod('put', url, data);
  }

  public patch(url, data) {
    return this.http.commonHttpMethod('patch', url, data);
  }

  public getStream(url, data) {
    return this.http.commonHttpMethod('getStream', url, data);
  }

  public delete(url, data) {
    return this.http.commonHttpMethod('delete', this.pathParam(url, data), null);
  }

  public getAuthNoSpinner(url, query) {
    return this.http.commonHttpMethodNoSpinnter('auth', this.pathParam(url, query), null);
  }

  public getNoSpinner(url, data) {
    return this.http.commonHttpMethodNoSpinnter('get', this.pathParam(url, data), null);
  }

  public postNoSpinner(url, data) {
    return this.http.commonHttpMethodNoSpinnter('post', url, data);
  }

  public putNoSpinner(url, data) {
    return this.http.commonHttpMethodNoSpinnter('put', url, data);
  }

  public patchNoSpinner(url, data) {
    return this.http.commonHttpMethodNoSpinnter('patch', url, data);
  }

  public getStreamNoSpinner(url, data) {
    return this.http.commonHttpMethodNoSpinnter('getStream', url, data);
  }

  public deleteNoSpinner(url, data) {
    return this.http.commonHttpMethodNoSpinnter('delete', this.pathParam(url, data), null);
  }

  public postImage(url: string, formData: FormData) {
    return this.http.commonHttpMethod('postImage', url, formData);
  }

};

 