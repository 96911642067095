export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Home',
					root: true,
					alignment: 'left',
					page: '/home',
					translate: 'MENU.HOME',
					role: [ 
						{
							name : 'ADMIN'
						},
						{
							name : 'AFS'
						},
						{
							name : 'WT_COLLECTOR',
							isShowMenu : 'N'
						},
						{
							name : 'WT_OFFICE',
							isShowMenu : 'N'
						},
						{
							name : 'CUSTOMER'
						}
					]
				},
				{
					title: 'ChecK In-OUT',
					root: true,
					alignment: 'left',
					page: '/checkInOut',
					translate: 'MENU.CHECKINOUT',
					role: [ 
						{
							name : 'ADMIN'
						},
						{
							name : 'AFS'
						},
						{
							name : 'WT_OFFICE'
						},
						{
							name : 'WT_COLLECTOR'
						},
						{
							name : 'WT_OFFICE'
						},
						{
							name : 'CUSTOMER',
							isShowMenu : 'N'

						}
					]
				},
				{
					title: 'Report',
					root: true,
					alignment: 'left',
					translate: 'MENU.REPORT',
					role: [
						{
							name : 'ADMIN'
						},
						{
							name : 'AFS'
						},
						{
							name : 'WT_OFFICE'
						},
						{
							name : 'CUSTOMER'
						}
					],
					submenu: [
						{
							title: 'Report Manifest',
							bullet: 'dot',
							page: '/reportManifest',
							translate: 'MENU.REPORT_MANIFEST',
						},
						{
							title: 'Report Summary',
							bullet: 'dot',
							page: '/reportSummary',
							translate: 'MENU.REPORT_SUMMARY',
						},
						{
							title: 'Report Municipality',
							bullet: 'dot',
							page: '/reportMunicipality',
							translate: 'MENU.REPORT_MUNICIPALITY',
							role: [
								{
									name : 'ADMIN'
								},
								
								{
									name : 'AFS'
								}
							]
						},
						{
							title: 'Report Accounting',
							bullet: 'dot',
							page: '/reportAccounting',
							translate: 'MENU.REPORT_ACCOUNTING',
							role: [
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								}
							]
						},
						{
							title: 'Report Request',
							bullet: 'dot',
							page: '/reportRequest',
							translate: 'MENU.REPORT_REQUEST',
							role: [
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								}
							]
						}
					]
				},
				{
					title: 'Request',
					root: true,
					alignment: 'left',
					translate: 'MENU.REQUEST',
					submenu: [
						{
							title: 'Request Complaint',
							bullet: 'dot',
							page: '/complaint',
							translate: 'MENU.REQUEST_COMPLAINT',
						},
						{
							title: 'Summary of Complaints',
							bullet: 'dot',
							page: '/complaintTable',
							translate: 'MENU.SUMMARY_COMPLAINT',
							role: [
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								},
								{
									name : 'WT_COLLECTOR'
								},
								{
									name : 'WT_OFFICE'
								},
								{
									name : 'CUSTOMER'
								}
							]
						},
					]
				},
				{
					title: 'Summary',
					root: true,
					alignment: 'left',
					page: '/summary',
					translate: 'MENU.SUMMARY',
					role: [ 
						{
							name : 'ADMIN'
						},
						{
							name : 'AFS'
						},
						{
							name : 'WT_COLLECTOR'
						},
						{
							name : 'CUSTOMER',
						},
						{
							name : 'WT_OFFICE',
						}
					]
				},
				{
					title: 'Company',
					root: true,
					alignment: 'left',
					page: '/masterCompany/edit',
					translate: 'MENU.MASTERCOMPANY_EMPLOYEE',
					role: [
						{
							name : 'CUSTOMER',
						}
					]
				},
				{
					title: 'Master Data',
					root: true,
					alignment: 'left',
					translate: 'MENU.MASTERDATA',
					role: [
						{
							name : 'ADMIN'
						},
						{
							name : 'AFS'
						},
						{
							name : 'WT_OFFICE',
						}
					],
					submenu: [
						{
							title: 'Company',
							bullet: 'dot',
							page: '/masterCompany',
							translate: 'MENU.MASTERCOMPANY',
							role: [
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								},
								{
									name : 'CUSTOMER',
								}
							]
						},
						{
							title: 'User Management',
							bullet: 'dot',
							page: '/masterUsermanagement',
							translate: 'MENU.USERMANAGEMENT',
							role: [ 
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								},
								{
									name : 'WT_COLLECTOR',
									isShowMenu : 'N'
								},
								{
									name : 'WT_OFFICE'
								},
								{
									name : 'CUSTOMER',
									isShowMenu : 'N'
								}
							]
						},
						{
							title: 'Price & Unit',
							bullet: 'dot',
							page: '/masterPriceUnit',
							translate: 'MENU.MASTERPRICEUNIT',
							role: [
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								}
							]
						},
						{
							title: 'collector',
							bullet: 'dot',
							page: '/masterCollector',
							translate: 'MENU.MASTERCOLLECTOR',
							role: [
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								}
							]
						},
						{
							title: 'Dashboard Information',
							bullet: 'dot',
							page: '/masterDashborad',
							translate: 'MENU.MASTERDASHBOARD',
							role: [ 
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								}
							]
						},
						{
							title: 'App Configulation',
							bullet: 'dot',
							page: '/masterConfigApp',
							translate: 'MENU.APPCONFIG',
							role: [
								{
									name : 'ADMIN'
								},
								{
									name : 'AFS'
								}
							]
						},
					]
				},

				
			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Home',
					root: true,
					page: '/home',
					translate: 'MENU.HOME',
					bullet: 'dot',
				},
				{
					title: 'ChecK In-OUT',
					root: true,
					page: '/checkInOut',
					translate: 'MENU.CHECKINOUT',
					bullet: 'dot',
				},
				{
					title: 'Report',
					root: true,
					page: '/report',
					translate: 'MENU.REPORT',
					bullet: 'dot',
				},
				{section: 'Complaint'},
				{
					title: 'Request Complaint',
					bullet: 'dot',
					root: true,
					page: '/complaint',
					translate: 'MENU.REQUEST_COMPLAINT',
					
				},
				{
					root: true,
					title: 'Summary of Complaints',
					bullet: 'dot',
					page: '/complaintTable',
					translate: 'MENU.SUMMARY_COMPLAINT',
					
				},
				{
					title: 'Summary',
					root: true,
					bullet: 'dot',
					page: '/summary',
					translate: 'MENU.SUMMARY',
					
				}
				
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
	public loadConfig(menu): any {
		let defaults: any = {
			header: {
				self: {},
				items: []
			},
			aside: {
				self: {},
				items: []
			}
		}
		if(menu)
		for(var i=0;i<menu.length;i++){
			if(menu[i].isShow && menu[i].isShow=='Y'){
				defaults.header.items.push({
					title: menu[i].menu_name,
					root: true,
					alignment: 'left',
					icon: menu[i].menu_icon,
					page: menu[i].menu_path,
					translate: menu[i].menu_name,
					submenu: this.getMenuSub(menu[i].menu_sub? menu[i].menu_sub : null)
				});
				defaults.aside.items.push({
					title: menu[i].menu_name,
					root: true,
					alignment: 'left',
					icon: menu[i].menu_icon?menu[i].menu_icon: null,
					page: menu[i].menu_path,
					translate: menu[i].menu_name,
					submenu: this.getMenuSub(menu[i].menu_sub? menu[i].menu_sub : null)
				});
			}
		}
		// console.log(defaults);
		return defaults
	}

	public getMenuSub(menusub){
		let menu = []
		if(menusub && menusub.length > 0 ){
			for(var i =0;i<menusub.length ; i++){
				if(menusub[i].isShow && menusub[i].isShow=='Y'){
					menu.push({
						title: menusub[i].menu_name,
						bullet: 'dot',
						icon: menusub[i].menu_icon?menusub[i].menu_icon: null,
						translate: menusub[i].menu_name,
						page: menusub[i].menu_path,
					})
				}
			}
			return menu
		}else{
			return null
		}
		
	}
}
