import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { environment}  from '../../../../../environments/environment';
import { of , Observable, throwError } from 'rxjs';
import { tap ,timeout ,retry, catchError,finalize  } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable()
export class Http {
  private redirectUrlName = 'redirectUrl';
  private countRequestService=0;
  private countResponseService=0;
  constructor(
    private http: HttpClient,
    private loadingService: LoadingBarService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  private setHeader(method) {
    let headers = new HttpHeaders({
        'Authorization': 'Bearer '+localStorage['token-webPortal'],
      });
    // POST, GET, PATCH, OPTIONS, DELETE
    if (method === 'post') {
      headers = headers.set('Content-Type', 'application/json');
    } else if (method === 'patch') {
      headers = headers.set('Content-Type', 'application/json');
    } else if (method === 'put') {
      headers = headers.set('Content-Type', 'application/json');
    } else if (method == 'postImage') {
      // headers = headers.set('Content-Type', 'multipart/form-data;charset=ISO-8859-1');
    }
    let httpOptions={}
    if(method == 'getStream') {
      httpOptions = {responseType:"blob"}
    }else{
      httpOptions = {headers}
    }
    return httpOptions;
  }

  private handleError<T> (result?: T) {
    return (error: any): Observable<T> => {
      this.countRequestService+=1;
      this.checkCallService();
      console.error(error); // log to console instead
      this.unauthorizeHandle(error);
      let errorMessage = '';
      this.loadingService.complete();
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      // alert(errorMessage);
      // Let the app keep running by returning an empty result.
      return throwError(error);
    };
  }

  unauthorizeHandle(error) {
    if (error.status === 401 || error.status === 403) {
      if(this.router.url.search("error") < 0 )
      localStorage.setItem(this.redirectUrlName, this.router.url);
      this.router.navigate(['error/'+error.status]);
      return true;
    } else {
      if(this.router.url.search("error") < 0 )
      localStorage.setItem(this.redirectUrlName, this.router.url);
      this.router.navigate(['error/'+error.status]);
      return true;
    }
  }

  public checkCallService(){
    this.loadingService.complete();
    this.countResponseService=this.countResponseService+1;
    if(this.countRequestService===this.countResponseService){
      this.countRequestService=0;
      this.countResponseService=0;
      this.spinner.hide()
    }
  }

  retrieveToken(username,password,url) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', username);
    params.append('password', password);

    let headers = new HttpHeaders({ 
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Authorization': 'Basic ' + environment.authorization
      // 'Authorization': 'Basic ' + btoa(environment.clientId + ":"+environment.clientSecret) 
    });
    // this.loadingService.start();
    return this.http.post(url, params.toString(), { headers: headers }).pipe(
      retry(5),
      timeout(10000),
      // catchError(this.handleError()),
      // finalize(() => this.checkCallService())
    );
  }


  public commonHttpMethod(methodType, url, body) {
    let options = this.setHeader(methodType);
    this.loadingService.start();
    this.countRequestService=this.countRequestService+1;
    this.spinner.show();
    switch (methodType) {
      case 'get': return this.http.get<any>(url, options).pipe(
        retry(5),
        timeout(60000),
        catchError(this.handleError()),
        finalize(() => this.checkCallService())
      );
      case 'post': return this.http.post<any>(url, JSON.stringify(body), options).pipe(
        retry(5),
        timeout(600000),
        catchError(this.handleError()),
        finalize(() => this.checkCallService())
      );
      case 'patch':return  this.http.patch<any>(url, JSON.stringify(body), options).pipe(
        retry(5),
        timeout(60000),
        catchError(this.handleError()),
        finalize(() => this.checkCallService())
      );
      case 'put': return this.http.put<any>(url, JSON.stringify(body), options).pipe(
        retry(5),
        timeout(60000),
        catchError(this.handleError()),
        finalize(() => this.checkCallService())
      );
      case 'delete': return this.http.delete<any>(url, options).pipe(
        retry(5),
        timeout(60000),
        catchError(this.handleError()),
        finalize(() => this.checkCallService())
      );
      case 'getStream':return this.http.post<any>(url, JSON.stringify(body),options).pipe(
        retry(5),
        timeout(60000),
        catchError(this.handleError()),
        finalize(() => this.checkCallService())
      );
      case 'auth': return this.http.get<any>(url).pipe(
        retry(5),
        timeout(60000),
        catchError(this.handleError()),
        finalize(() => this.checkCallService())
      );
      case 'postImage': return this.http.post<any>(url,body,options).pipe(
        retry(5),
        timeout(60000),
        catchError(this.handleError()),
        finalize(() => this.checkCallService())
      );
    }
  }
  
  public commonHttpMethodNoSpinnter(methodType, url, body) {
    let options = this.setHeader(methodType);
    // this.countRequestService=this.countRequestService+1;
    switch (methodType) {
      case 'get': return this.http.get<any>(url, options).pipe(
        retry(5),
        timeout(30000),
        // catchError(this.handleError())
      );
      case 'post': return this.http.post<any>(url, JSON.stringify(body), options).pipe(
        retry(5),
        timeout(30000),
        // catchError(this.handleError())
      );
      case 'patch':return  this.http.patch<any>(url, JSON.stringify(body), options).pipe(
        retry(5),
        timeout(30000),
        // catchError(this.handleError())
      );
      case 'put': return this.http.put<any>(url, JSON.stringify(body), options).pipe(
        retry(5),
        timeout(30000),
        // catchError(this.handleError())
      );
      case 'delete': return this.http.delete<any>(url, options).pipe(
        retry(5),
        timeout(30000),
        // catchError(this.handleError())
      );
      case 'getStream':return this.http.post<any>(url, JSON.stringify(body),options).pipe(
        retry(5),
        timeout(30000),
        // catchError(this.handleError())
      );
      case 'auth': return this.http.get<any>(url).pipe(
        retry(5),
        timeout(30000),
        // catchError(this.handleError())
      );
    }
  }



}
