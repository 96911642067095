import {Component, forwardRef, Input, OnInit,Output, EventEmitter} from '@angular/core';
import {ControlValueAccessor, NgModel,NG_VALUE_ACCESSOR} from '@angular/forms';
import { BsDatepickerConfig ,BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { AppProfileService } from '../../../../core/_base/layout/services/appprofile.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { HostListener, ViewChild } from '@angular/core';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent } from '../../../partials/layout';
@Component({
  selector: 'common-datepicker[ngModel]',
  templateUrl: './common-datepicker.component.html',
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => CommonDatepickerComponent),
  //     multi: true
  //   }
  // ]
})

export class CommonDatepickerComponent implements ControlValueAccessor, OnInit {
  
  @ViewChild(BsDatepickerDirective, { static: false }) datepicker: BsDatepickerDirective;


  @Input('useIsoString')useIsoString:boolean = false;
  @Input('placeHolder')placeHolder: string = "";
  @Input('id')id: string = "";
  @Input('disabled')disabled:boolean = false;
  @Input('isRange')isRange:boolean = false;
  @Input('datesDisabled')datesDisabled:boolean = false;
  @Input('minDate')
  public get minDate(){
    return this.dateStart;
  }
  public set minDate(value: any) {
    if(value){
      if(value instanceof Date){
        this.dateStart = value;
      }else{
        let date = new Date(value*1000);
        date.setMinutes(date.getMinutes()+ date.getTimezoneOffset()+ (+this.appProfileService.userProfile.information.timezone.time_offset));
        this.dateStart = date;
      }
    }else{
      this.dateStart = null;
    }
    
  }
  @Input('maxDate')
  public get maxDate() {
    return this.dateEnd;
  }
  public set maxDate(value) {
    if(value){
      if(value instanceof Date){
        this.dateEnd = value;
      }else{
        let date = new Date(value*1000);
        date.setMinutes(date.getMinutes() + (+this.appProfileService.userProfile.information.timezone.time_offset) + date.getTimezoneOffset());
        this.dateEnd = date;
      }
    }else{
      this.dateEnd = null;
    }
  }
  ยีิสร
  @Input('lastTime')lastTime:boolean = false;
  @Input('dateInputFormat')dateInputFormat:string = "";
  @Input('minMode')minMode: string = "day";
  @Input('showWeekNumbers')showWeekNumbers:boolean = false;
  @Input('language')
  public get language() {
    return this.datelanguage;
  }
  public set language(value) {
    this.datelanguage = value;
    this.localeService.use(this.datelanguage);
  }


  public storage = localStorage;
  bsConfig :Partial<BsDatepickerConfig>;
  onTouchedCallback:Function;
  onChangeCallback: Function;
  dateValue: Date;
  dateStart:any;
  dateEnd:any;
  datelanguage:string;
  today : Date;
  constructor(
    private dateChangedNgModel: NgModel,
    public appProfileService : AppProfileService,
    private datePipe: DatePipe,
    private localeService: BsLocaleService,
    private modalService: BsModalService,
  ) {

    this.dateChangedNgModel.valueAccessor = this;
  }

  ngOnInit() {
    if(this.minMode=="day"){
      if(this.placeHolder =="")this.placeHolder ="DD/MM/YYYY";
      if(this.dateInputFormat =="")this.dateInputFormat="DD/MM/YYYY";
    }else if(this.minMode=="month"){
      if(this.placeHolder =="")this.placeHolder ="MM/YYYY";
      if(this.dateInputFormat =="")this.dateInputFormat="MM/YYYY";
    }else if(this.minMode=="year"){
      if(this.placeHolder =="")this.placeHolder ="YYYY";
      if(this.dateInputFormat =="")this.dateInputFormat="YYYY";
    }
    this.bsConfig = Object.assign({}, { 
      dateInputFormat: this.dateInputFormat,
      rangeInputFormat: this.dateInputFormat,
      showWeekNumbers: this.showWeekNumbers,
      isAnimated: true,
    });
    this.localeService.use(this.storage['language']);


  }

 
  //get accessor
  get value(): any {
    return this.dateValue;
  };
  set value(v: any) {
    if(v == null){
      this.dateChangedNgModel.viewToModelUpdate(null);
      return;
    } 

    this.dateValue = v;
    if(this.isRange){
      if(v){
        let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        let firstDate = new Date(v[0]).getTime();
        let secondDate = new Date(v[1]).getTime();
        const diffDays = Math.round((secondDate - firstDate) / oneDay);
        if(diffDays > 90){
          let modalRef = this.modalService.show(AlertModalComponent,{ class: 'modal-sm modal-dialog-centered'});
          modalRef.content.setModal('warning', "MESSAGE_ERROR.STATUS_SUCESS", "COMMON.MESSAGE_MAX_DATE_90DAY");
          this.dateChangedNgModel.viewToModelUpdate(null);
          this.dateValue = new Date()
          return;
        }
      }
      this.dateChangedNgModel.viewToModelUpdate(v);
      return;
    }
    if(v && v instanceof Date){
      if(this.minMode == 'day'){
        if(this.useIsoString){
          this.dateChangedNgModel.viewToModelUpdate(this.datePipe.transform(new Date(v), 'yyyy/MM/dd'));
        }else {
          if(!this.lastTime){
            let date = new Date(new Date(v).getFullYear(), new Date(v).getMonth(), new Date(v).getDate());
            date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
            this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
          }else{
            let date = new Date(new Date(v).getFullYear(), new Date(v).getMonth(), new Date(v).getDate(),23,59,59);
            date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
            this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
          }
        }
      }else if(this.minMode == 'month'){
        if(this.useIsoString){
          this.dateChangedNgModel.viewToModelUpdate(this.datePipe.transform(new Date(v), 'yyyy/MM'));
        }else {
          if(!this.lastTime){
            let date = new Date(new Date(v).getFullYear(), new Date(v).getMonth(), 1);
            date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
            this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
          }else{
            let date = new Date(new Date(v).getFullYear(), new Date(v).getMonth(), 0,23,59,59);
            date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
            this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
          }
        }
      }else if(this.minMode == 'year'){
        if(this.useIsoString){
          this.dateChangedNgModel.viewToModelUpdate(this.datePipe.transform(new Date(v), 'yyyy'));
        }else {
          if(!this.lastTime){
            let date = new Date(new Date(v).getFullYear(), 1, 1);
            date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
            this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
          }else{
            let date = new Date(new Date(v).getFullYear(), 0, 0,23,59,59);
            date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
            this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
          }
        }
      }
    } else {
      this.dateChangedNgModel.viewToModelUpdate(null);
    }
  }

  changeDate(event){

  }


  writeValue(v: any): void {
    if(v){
      let dateTemp;

      if(this.isRange){
        if(v){
          let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          let firstDate = new Date(v[0]).getTime();
          let secondDate = new Date(v[1]).getTime();
          const diffDays = Math.round((secondDate - firstDate) / oneDay);
          if(diffDays > 90){
            let modalRef = this.modalService.show(AlertModalComponent,{ class: 'modal-sm modal-dialog-centered'});
            modalRef.content.setModal('warning', "MESSAGE_ERROR.STATUS_SUCESS", "COMMON.MESSAGE_MAX_DATE_90DAY");
            this.dateChangedNgModel.viewToModelUpdate(null);
            this.dateValue = new Date()
          }else{
            this.dateChangedNgModel.viewToModelUpdate(v);
            this.dateValue = v;
          }
        }else{
          this.dateChangedNgModel.viewToModelUpdate(v);
          this.dateValue = v;
        }
        return;
      }
      if(v instanceof Date){
        dateTemp = v;
      }else if (Date.parse(v)){
        dateTemp = new Date(""+v);
      }else{
        let date = new Date(v*1000);
        date.setMinutes(date.getMinutes()+ date.getTimezoneOffset()+ (+this.appProfileService.userProfile.information.timezone.time_offset));
        dateTemp = date;
      }
      if (dateTemp !== this.dateValue) {
        this.dateValue = dateTemp;
        // this.value = dateTemp;

        if(this.minMode == 'day'){
          if(this.useIsoString){
            this.dateChangedNgModel.viewToModelUpdate(this.datePipe.transform(new Date(this.dateValue), 'yyyy/MM/dd'));
          }else {
            if(!this.lastTime){
              let date = new Date(new Date(this.dateValue).getFullYear(), new Date(this.dateValue).getMonth(), new Date(this.dateValue).getDate());
              date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
              this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
            }else{
              let date = new Date(new Date(this.dateValue).getFullYear(), new Date(this.dateValue).getMonth(), new Date(this.dateValue).getDate(),23,59,59);
              date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
              this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
            }
          }
        }else if(this.minMode == 'month'){
          if(this.useIsoString){
            this.dateChangedNgModel.viewToModelUpdate(this.datePipe.transform(new Date(this.dateValue), 'yyyy/MM'));
          }else {
            if(!this.lastTime){
              let date = new Date(new Date(this.dateValue).getFullYear(), new Date(this.dateValue).getMonth(), 1);
              date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
              this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
            }else{
              let date = new Date(new Date(this.dateValue).getFullYear(), new Date(this.dateValue).getMonth(), 0,23,59,59);
              date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
              this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
            }
          }
        }else if(this.minMode == 'year'){
          if(this.useIsoString){
            this.dateChangedNgModel.viewToModelUpdate(this.datePipe.transform(new Date(this.dateValue), 'yyyy'));
          }else {
            if(!this.lastTime){
              let date = new Date(new Date(this.dateValue).getFullYear(), 1, 1);
              date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
              this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
            }else{
              let date = new Date(new Date(this.dateValue).getFullYear(), 0, 0,23,59,59);
              date.setMinutes(date.getMinutes()- this.appProfileService.userProfile.information.timezone.time_offset - date.getTimezoneOffset());
              this.dateChangedNgModel.viewToModelUpdate(date.getTime()/1000);
            }
          }
        }
      }

    }else{
      this.dateValue = null;
    }
    
  

 
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }



}
