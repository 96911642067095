import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() { }

  public exportExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportExcelFileCustom(json: any[], excelFileName: string, sheet2: string, sheetNames: string[]): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { [sheet2] : worksheet }, SheetNames: sheetNames };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportExcelFileCustomMultiSheet(json: any[], excelFileName: string, sheetNames: string[]): void {
    var workbook = XLSX.utils.book_new();
    var mySheetName: string;
    for(var i=0;i<json.length;i++){
      mySheetName = sheetNames[i];
      mySheetName = mySheetName.replace("\\", " ");
      mySheetName = mySheetName.replace("/", " ");
      mySheetName = mySheetName.replace("?", " ");
      mySheetName = mySheetName.replace("*", " ");
      mySheetName = mySheetName.replace("[", " ");
      mySheetName = mySheetName.replace("]", " ");
      console.log('mySheetName',mySheetName)
      XLSX.utils.book_append_sheet(workbook,XLSX.utils.json_to_sheet(json[i]), mySheetName);
    }
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportExcelFileCustomMultiSheetPP(json: any[], excelFileName: string, sheetNames: string[]): void {
    var workbook = XLSX.utils.book_new();
    var mySheetName: string;
    for(var j=0;j<sheetNames.length;j++){
      mySheetName = sheetNames[j];
      mySheetName = mySheetName.replace("\\", " ");
      mySheetName = mySheetName.replace("/", " ");
      mySheetName = mySheetName.replace("?", " ");
      mySheetName = mySheetName.replace("*", " ");
      mySheetName = mySheetName.replace("[", " ");
      mySheetName = mySheetName.replace("]", " ");
      console.log('mySheetName',mySheetName)
      XLSX.utils.book_append_sheet(workbook,XLSX.utils.json_to_sheet(json[j]), mySheetName);
    }
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFilePP(excelBuffer, excelFileName);
  }
  private saveAsExcelFilePP(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName+ EXCEL_EXTENSION);
    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public importExcelFile(file): Observable<any> {
    let reader = new FileReader();
    let _this = this;
    return Observable.create(observer => {
      reader.onload = function (e) {
        let data = e.target['result'];
        let workbook = XLSX.read(data, {
            type: 'binary'
        });
        let jsonSheet = {};
        for (var sheetNumber = 0 ; sheetNumber < workbook.SheetNames.length ; sheetNumber++) {
          jsonSheet[workbook.SheetNames[sheetNumber]] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[sheetNumber]]);
        }
        observer.next(jsonSheet);
        observer.complete();
      }
      
      reader.readAsBinaryString(file);
    });
  }
}