// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable ,Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {AppProfileService} from '../../_base/layout/services/appprofile.service';
import {AuthorizationService } from '../../_base/layout/services/authorization.service';
import { environment } from '../../../../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
@Injectable()
export class AuthGuard implements CanActivate {
    // private isLoadUser = false;
    constructor(
        private router: Router,
        private authService: AuthorizationService,
        private appProfileService : AppProfileService,
        private permissionsService: NgxPermissionsService,
        private authorizationService : AuthorizationService,
        ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        var subject = new Subject<boolean>();
        if(state.url != "/home")
        {
            this.authorizationService.snapRequestUrl = state.url
        }

        if (this.authService.isLoggedin()) {
            if(!this.authService.isLoadUser()){
                this.authService.getUserDetail(null).subscribe(
                    data =>{
                        let username = data[0]['username'].split('')
                        this.appProfileService.userProfile = {
                            information : {
                                first_name: data[0]['first_name'],
                                last_name: data[0]['last_name'],
                                timezone : {
                                    time_offset : 420
                                }
                            },
                            photo : {
                                image : "https://dev.scgd.co/?txt="+username[0]+'+'+username[1]
                            },
                            username : data[0]['username'],
                            wtdetail : data[0]['wt_detail'],
                            company : data[0]['company_code']
                        }
                        this.appProfileService.role = data[0]['role_name']
                        this.permissionsService.flushPermissions();
                        this.permissionsService.addPermission(this.appProfileService.role);
                        this.appProfileService.appConfig = data[1]
                        this.authService.setLoadUserDetail();
                        if((location.pathname =="/auth/login" || location.pathname =="/" ) && ( this.appProfileService.role == 'WT_COLLECTOR' )){
                            if (this.authorizationService.snapRequestUrl != "/home") {
                                this.router.navigateByUrl(this.authorizationService.snapRequestUrl);
                            }
                            else
                                this.router.navigate(['/summary']);
                        }
                        else if((location.pathname =="/auth/login" || location.pathname =="/" )  && ( this.appProfileService.role == 'AFS' || this.appProfileService.role == 'CUSTOMER' )){
                            if (this.authorizationService.snapRequestUrl != "/home") {
                                this.router.navigateByUrl(this.authorizationService.snapRequestUrl);
                            }
                            else
                                this.router.navigate(['/home']);
                        }
                        else if((location.pathname =="/auth/login" || location.pathname =="/" )  && ( this.appProfileService.role == 'WT_OFFICE' ))
                        {
                            if (this.authorizationService.snapRequestUrl != "/home") {
                                this.router.navigateByUrl(this.authorizationService.snapRequestUrl);
                            }
                            else
                                this.router.navigate(['/reportManifest']);
                        }
                        subject.next(true);
                    },
                    error=>{
                        this.authService.forwardToLogin(state.url);
                        subject.next(false);
                    }
                );
            }else{
                return true;
            }
            return subject.asObservable();
        }else{
            this.authService.forwardToLogin(state.url);
            return false;
        }
    }

    
    
}
