// Angular
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
@Injectable()
export class CustomDateService {

    public storage = localStorage;
	// Public properties
	constructor() {
    }
    
    customDate(date: Date | string, format: string = 'yyyy-MM-dd'){
        if(date != null){
            if(this.storage['language'] == 'th'){
              let ThaiDay = ['อาทิตย์','จันทร์','อังคาร','พุธ','พฤหัสบดี','ศุกร์','เสาร์']
              let shortThaiMonth = [
                  'ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.',
                  'ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'
                  ];
                  
              let shortMonthcode = [
                    '01','02','03','04','05','06',
                    '07','08','09','10','11','12'
                    ];      
              let longThaiMonth = [
                  'มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน',
                  'กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'
                  ];   
              let inputDate=new Date(date);
              
              let dataDate = [
                  inputDate.getDay(),inputDate.getDate(),inputDate.getMonth(),inputDate.getFullYear(),inputDate.getHours(),inputDate.getMinutes()
                  ];
              let outputDateFull = [
                  'วัน '+ThaiDay[dataDate[0]],
                  'ที่ '+dataDate[1],
                  'เดือน '+longThaiMonth[dataDate[2]],
                  'พ.ศ. '+(dataDate[3]+543)
              ];
              let outputDateShort = [
                  dataDate[1],
                  shortThaiMonth[dataDate[2]],
                  dataDate[3]+543
              ];
              let outputDateMedium = [
                  dataDate[1],
                  longThaiMonth[dataDate[2]],
                  dataDate[3]+543
              ];    
              let outputDateTimeFull = [
                'วัน '+ThaiDay[dataDate[0]],
                'ที่ '+dataDate[1],
                'เดือน '+longThaiMonth[dataDate[2]],
                'พ.ศ. '+(dataDate[3]+543),
                'เวลา '+(dataDate[4] < 10 ? '0'+dataDate[4] : dataDate[4]) +':'+(dataDate[5] < 10 ? '0'+dataDate[5] : dataDate[5])+' น.'
            ];
            let outputDateTimeShort = [
                dataDate[1],
                shortThaiMonth[dataDate[2]],
                dataDate[3]+543,
                (dataDate[4] < 10 ? '0'+dataDate[4] : dataDate[4]) +':'+(dataDate[5] < 10 ? '0'+dataDate[5] : dataDate[5])+' น.'
            ];
            let outputDateTimeMedium = [
                dataDate[1],
                longThaiMonth[dataDate[2]],
                dataDate[3]+543,
                (dataDate[4] < 10 ? '0'+dataDate[4] : dataDate[4]) +':'+(dataDate[5] < 10 ? '0'+dataDate[5] : dataDate[5])+' น.'
            ];    
      
            let outputTime = [
              (dataDate[4] < 10 ? '0'+dataDate[4] : dataDate[4]) +':'+(dataDate[5] < 10 ? '0'+dataDate[5] : dataDate[5])+' น.'
            ];   
              let returnDate:string;
              returnDate = outputDateMedium.join(" ");
              if(format=='longDate'){
                  returnDate = outputDateFull.join(" ");
              }else if(format=='mediumDate'){
                  returnDate = outputDateMedium.join(" ");
              }else if(format=='shortDate'){
                  returnDate = outputDateShort.join(" ");
              }else if(format=='long'){
                returnDate = outputDateTimeFull.join(" ");
              }else if(format=='medium'){
                  returnDate = outputDateTimeMedium.join(" ");
              }else if(format=='short'){
                  returnDate = outputDateTimeShort.join(" ");
              }else if(format=='shortTime'){
                returnDate = outputTime.join(" ");
              }else if(format=='monthYear'){
                    returnDate = outputDateMedium[1]+" พ.ศ "+outputDateMedium[2]
              }else if(format=='year'){
                    returnDate = "พ.ศ "+outputDateMedium[2]
              }else if(format=='month'){
                    returnDate = ""+outputDateMedium[1]
              }else if(format=='day'){
                    returnDate = 'วัน '+ThaiDay[dataDate[0]]+' ที่ '+dataDate[1]
             }else{
                return dataDate[1] + '/' + shortMonthcode[(dataDate[2])] +'/' + (dataDate[3]+543);
              }    
              return returnDate;
            }else{
                if(format=='monthYear'){
                    return new DatePipe('en-US').transform(date, 'MMMM , y');
                }else if(format=='year'){
                    return new DatePipe('en-US').transform(date, 'y');
                }else if(format=='day'){
                    return new DatePipe('en-US').transform(date, 'EEEE , dd');
                }else if(format=='month'){
                    return new DatePipe('en-US').transform(date, 'MMMM');
                }else if(format=='shortDate'){
                    return new DatePipe('en-US').transform(date, 'MMM dd, y');
                }else{
                    return new DatePipe('en-US').transform(date, format);
                }
            }
          }
    }

	
}
