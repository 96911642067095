// USA
export const locale = {
	lang: 'en',
	data: {
		MENU:{
			SUMMARY : 'Summary',
			SUMMARY_COMPLAINT: 'Summary of Requests',
			REQUEST_COMPLAINT: 'Requests',
			REQUEST: 'Request',
			REPORT: 'Report',
			CHECKINOUT: 'Check In-Out',
			HOME: 'Home',
			REPORT_MANIFEST: 'Manifest Report ',
			REPORT_SUMMARY: 'Summary Report ',
			REPORT_ACCOUNTING: 'Accounting Report ',
			REPORT_REQUEST: 'Requested Report',
			MASTERDATA : 'Master Data',
			MASTERCOMPANY : 'Customer Management',
			USERMANAGEMENT : 'User Managament',
			MASTERPRICEUNIT : 'Price & Unit',
			MASTERDASHBOARD : 'Dashboard Management',
			MASTERCOLLECTOR : 'Collector',
			APPCONFIG : 'App Configulation',
			REPORT_MUNICIPALITY : 'Municipality Report',
			MASTERCOMPANY_EMPLOYEE: 'Company Management'
		},
		COMMON : {
			AMATA_FACILITY : 'amata facility services co. ltd',
			REQUIRE_FIELD : 'Required Field',
			MORE_THAN_ZERO : '! More then 0',
			PLEASE_SELECT : 'Please Select',
			PLEASE_FILL : 'Please Fill ',
			LOADING : 'Loading...',
			SEARCH : 'Search',
			RESULTS_SEARCH : 'Results Search',
			NEXT : 'Next',
			PREVIOUS : 'Previous',
			EXPORT_EXCEL : 'Export Excel',
			OPEN_PDF : 'Open PDF',
			EXPORT_PDF : 'Export PDF',
			PRINT : 'Print',
			EXPORT_FILE : 'Export File',
			ORDER_NUMBER : 'No.',
			DATANOTFOUND : 'Data not found',
			SHOW : 'Show',
			CONDITION_SEARCH : 'Condition Search',
			DETAIL : 'Detail',
			SUBMIT_BUTTON: 'Submit',
			MONTH : 'Month',
			MONTH_01 : 'January',
			MONTH_02 : 'February',
			MONTH_03 : 'March',
			MONTH_04 : 'April',
			MONTH_05 : 'May',
			MONTH_06 : 'June',
			MONTH_07 : 'July',
			MONTH_08 : 'August',
			MONTH_09 : 'September',
			MONTH_10 : 'October',
			MONTH_11 : 'November',
			MONTH_12 : 'December',
			ACTION: 'Action',
			WELCOME : 'Hi',
			MYPROFILE : 'My Profile',
			ACCOUNT_SETTING : 'Account settings and more',
			SIGNOUT : 'Sign Out',
			MESSAGE_SUCESS : 'Sucessfully completed',
			STATUS_SUCESS : 'Sucess',
			MESSAGE_ERROR_DUPLICATE : 'Duplicate data', 
			MESSAGE_ERROR_PLEASE_FILL_MONTH : 'Please fill period',
			MESSAGE_ERROR_FILE_ERROR : 'Support only image, excel, pdf file types', 
			MESSAGE_ERROR_FEE_MORETHAN_ZERO : 'Fee must morn than zero', 
			MESSAGE_ERROR_FILE_SIZE_ERROR : 'The file size maximum 1 MB',
			MESSAGE_UPDATE_ERROR : 'Can not update ,please try again',
			MESSAGE_ERROR : 'something went wrong',
			MESSAGE_MAX_DATE_90DAY : 'Limit 90 days',
			STATUS_ERROR : 'Error',
			MESSAGE_NO_DATA : 'Not data',
			CLOSE_WINDOW : 'Close',
			VIEW_MORE : 'View more.',
			ChooseAction : 'Choose an action',
			ENABLE : 'Enabled',
			DISABLED : 'Disabled',
			ROLE: 'Role',
			USERNAME: 'Username',
			FIRST_NAME : 'First Name',
			LAST_NAME : 'Last Name',
			PHONE_NO : 'Phone No.',
			EMAIL : 'Email',
			POSITION : 'Position',
			COMPANY : 'Customer',
			FACTORY_COMPANY : 'Company',
			COLLECTOR : 'Collector',
			CREATED_BY : 'Created By',
			UPDATED_DATE : 'Updated Date',
			ACTIVE: 'Active',
			FULLNAME: 'Fullname',
			KILOGRAM: 'Kilograms',
			VIEW_DETAIL : 'View Detail',
			ALPHABHET_ONLY: 'Aphabets only',
			ALPHABHET_EN_NUM_ONLY: 'Only English\'s Aphabets, Digits and Underscore',
			PHONE_FORMAT: 'Invalid phone number (must not include spaces or special characters)',
			EMAIL_FORMAT: 'Invalid Email Address',
			PLATE_FORMAT: 'Invalid Plate Number',
			UNAME_DUP: 'Username\'s already in use',
			EMAIL_DUP: 'Email\'s already in use',
			ADMIN_MESSAGE: 'Admin\'s message',
			FILE_PIC: 'Pic/File',
			COLLECT_TRASH: 'Collect',
			ATLEAST_6_PASS: 'Atleast 6 characters required',
			CLICK : 'Click',
			MESSAGE_ERROR_APPROVED : 'Transaction\'s already approved',
			MESSAGE_ERROR_CANCELLED : 'Transaction\'s already cancelled',
			LESS_THAN_ZERO : 'Qty with less than 0 is not allowed',
			OPENED_REQ_TIME : 'Requested Time',
			ACK_REQ_TIME: 'Acknowledged Time',
			RESOLVED_REQ_TIME: 'Resolved Time',
			COMP_DUP : 'Customer Code\'s already in use',
			FROM : 'From',
			COMPLAINT_TO : 'Complaint to',
			IMAGE_ATTCH : 'Attach Image',
			COMPANY_CODE : 'Company Code'
		},
		LANGUAGE :{
			THAI : 'Thai',
			ENG  : 'Eng'
		},
		AUTH: {
			MESSAGE:{
				RESET_PASSWORD_SUCESS : 'Please check email',
				EMAIL_NOT_REGISTER : 'Username or Email are incorrect',
				PASSWORD_NOT_MATCH : 'Password not matched',
				CHANGE_PASSWORD_SUCESS : 'update password successfully',
				AUTH_FAIL : 'username or password incorrect',
				SESSION_EXPIRED : 'Session Expired'
			},
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
				REGIST: 'Register'
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Completedly configure password'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			}
		},
		Activity : 'Activity',
		CANCELLED : "Cancelled",
		PENDING : "Pending", 
		APPROVED:  "Approved",
		REJECTED : "Rejected",
		WasteType : 'Type of Waste',
		Company : 'Customer',
		ReportType : 'Report Type',
		Request: 'Request',
		Daily : 'Daily',
		Monthly : 'Monthly',
		Yearly : 'Yearly',
		SubDistrict : 'Sub-district',
		District : 'District',
		Province : 'Province',
		Zipcode : 'Zipcode',
		Date : 'Date',
		Topic : 'Topic',
		Qty :  'Qty',
		Amount :  'Amount',
		Unit : 'Unit',
		Collector : 'Collector',
		Employee : 'Employee',
		Check_In_Time: 'Time in',
		Check_Out_Time: 'Time Out',
		CompanyName : 'Customer Name',
		AddressCompany: 'Address',
		BTN_Create: 'Create',
		BTN_Check_In: 'Check-In',
		BTN_Check_Out: 'Check-Out',
		BTN_Clear: 'Clear',
		BTN_Back: 'Back',
		BTN_Confirm: 'Confirm',
		BTN_Next: 'Next',
		BTN_Addwaste : 'Add',
		BTN_Cancel : 'Cancel',
		BTN_BEGIN_TRANSACATION : 'New Check In',
		BTN_Approve: 'Approve',
		BTN_Reject: 'Reject',
		BTN_Edit: 'Edit',
		BTN_Complaint: 'Complaint',
		BTN_Delete : 'Delete',
		BTN_Save : 'Save',
		BTN_Add : 'Add ',
		BTN_Upload_File : 'Upload',
		File : 'File , Picture',
		ForPlant : 'For Plant Customer',
		Total : 'Total',
		Change : 'Change',
		Confirm_transaction : 'Confirm Transaction',
		Please_Confirm_transaction : 'Are you sure you want to confirm transaction?',
		Please_Cancel_transaction : 'Are you sure you want to cancel transaction?',
		Please_Approve_transaction : 'Are you sure you want to approve transaction?',
		Please_Reject_transaction : 'Are you sure you want to reject transaction?',
		Remark : 'Remark',
		Request_Type : 'Request Type',
		Title : 'Title',
		Detail : 'Detail',
		Status : 'Status',
		Code : 'Code',
		Fee : 'Fee / Unit',
		Total_before_vat : 'Total before VAT',
		Total_before_discount : 'Total before discount',
		Total_discount : 'Discount',
		Discount_per_unit : 'Discount/Unit',
		Total_vat : 'Total VAT' ,
		Opened : "Opened" ,
		Acknowledged : "Acknowledged" ,
		Resolved : "Resolved" ,
		Requester : "Requester",
		Approved_by : 'Approved by',
		Acc_code : 'Acc Code',
		Create_Company : 'Create New Customer',
		Create_Collector : 'Create New Collection',
		UnitForBilling : 'Unit For Billing',
		UnitCollection : 'Unit Collection',
		SettingCollector :'Setting Waste Collector',
		UpdateDate : 'Updated date',
		UpdateBy : 'Updated by',
		CreatedDate :'Created Date',
		CreatedBy : 'Created By',
		User : 'User',
		CompanyType : 'Customer Type',
		Plate_No : 'Plate',
		UnitConvertion : 'Unit Convertion',
		Approve_transaction: 'Approval Confirmation',
		Driver : 'Driver',
		Customer : 'Customer',
		CUSTOMER : 'Customer',
		Customer_name : 'Customer Name',
		Customer_code : 'Customer Code',
		General : 'General',
		DetailWaste : 'Detail',
		Report_manifest_title : 'Daily Manifest',
		Report_summary_title : 'Summary waste collection by company',
		Report_municipality_title : 'Summary waste collection by Amata City Chonburi Lndustrial Estate',
		Report_request_title : 'Summary Request Report',
		Amata_city : 'Amata City Chonburi Lndustrial Estate',
		ReportWaste : 'Report Waste Collection',
		ReportMunicipality : 'Report waste collection in municipality',
		Municipality : 'Municipality',
		Create_User : 'Create New User',
		AccountedForCapacity :'Accounted For Capacity',
		ADMIN: 'Admin',
		WT_COLLECTOR: 'WT Collector',
		WT_OFFICE: 'WT Office',
		AFS: 'AFS',
		EMPLOYEE: 'Employee',
		NOT_ASSIGN: 'Unassigned',
		Billing_date : 'Billing date',
		Local : 'Language local',
		EveryDay : 'Every Day ',
		EveryLastDay_month : 'Every last day of month ',
		DASHBOARD_NEW : 'News / Public Relations',
		GenaratePDF_ALL_CUSTOMER : 'Genarate PDF All Customer',
		GenaratePDF_ALL_MUNICIPALITY  : 'Genarate PDF All Municipality',
		GenarateExcel_ALL_CUSTOMER : 'Generate Excel All Customer',
		WASTE_COLLECTION : 'Waste Collection',
		IF_EDIT_OPEN_REQUEST : '** NOTE : If you want to edit data,Please open request',
		TITLE_EDIT_DATA_CUSTOMER_COMPLAINT : 'Edit data customer [ {{code}} ] {{name}}',
		TITLE_EDIT_DATA_COLLECTOR_COMPLAINT : 'Edit data collector [ {{code}} ] {{name}}',
		IF_AFTER_DATE_NEXT_PERIOD :'** Records after {{date}} will be count as next month records according to billing cycle.',
		Image : "Image",
		Link : "Link",
		News : "News",
		SlideNews : "News Slide",
		Summary_collect_trash : "Summary Collection",
		UpdatedDate : "Updated Date",
		transaction : "Transactions",
		KG : "Kilograms",
		BK : "Buckets",
		BG : "Bags",
		ITEM_CANCELLED: "Item Cancelled",
		ITEM_PENDING: "Item Wait for Approved",
		ITEM_APPROVED: "Item Approved"
	}
		
	
};
