// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth/guard/guard_auth';
import { RoleGuard } from './core/auth/guard/guard_auth.role';
import { NgxPermissionsGuard } from 'ngx-permissions';
const routes: Routes = [
	{path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},
	// {path: 'auth', loadChildren: () => import('app/views/pages/authentication/auth.module').then(m => m.AuthModule)},
	{path: 'error/:code', component: ErrorPageComponent},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'home',
				loadChildren: () => import('app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
				canActivate: [RoleGuard], 
                data: { path: 'home'} 
			},
			// {
			// 	path: 'report',
			// 	loadChildren: () => import('app/views/pages/report/report.module').then(m => m.ReportModule)
			// },
			{
				path: 'checkInOut',
				loadChildren: () => import('app/views/pages/checkInOut/checkInOut.module').then(m => m.CheckInOutModule),
				canActivate: [RoleGuard], 
                data: { path: 'checkInOut'} 
			},
			{
				path: 'complaint',
				loadChildren: () => import('app/views/pages/complaint/complaint.module').then(m => m.ComplaintModule),
				canActivate: [RoleGuard], 
                data: { path: 'complaint'} 
			},
			{
				path: 'complaintTable',
				loadChildren: () => import('app/views/pages/complaint-table/complaint-table.module').then(m => m.ComplaintTableModule),
				canActivate: [RoleGuard], 
                data: { path: 'complaintTable'} 
			},
			{
				path: 'summary',
				loadChildren: () => import('app/views/pages/summary/summary.module').then(m => m.SummaryModule),
				canActivate: [RoleGuard], 
                data: { path: 'summary'} 
			},
			{
				path: 'reportSummary',
				loadChildren: () => import('app/views/pages/report-summary/report-summary.module').then(m => m.ReportSummaryModule),
				canActivate: [RoleGuard], 
                data: { path: 'reportSummary'} 
			},
			{
				path: 'reportManifest',
				loadChildren: () => import('app/views/pages/report-manifest/report-manifest.module').then(m => m.ReportManifestModule),
				canActivate: [RoleGuard], 
                data: { path: 'reportManifest'} 
			},
			{
				path: 'reportAccounting',
				loadChildren: () => import('app/views/pages/report-accounting/report-accounting.module').then(m => m.ReportAccountingModule),
				canActivate: [RoleGuard], 
                data: { path: 'reportAccounting'} 
			},
			{
				path: 'reportRequest',
				loadChildren: () => import('app/views/pages/report-request/report-request.module').then(m => m.ReportRequestModule),
				canActivate: [RoleGuard], 
                data: { path: 'reportRequest'} 
			},
			{
				path: 'masterCompany',
				loadChildren: () => import('app/views/pages/master-company/master-company.module').then(m => m.MasterCompanyModule),
				canActivate: [RoleGuard], 
                data: { path: 'masterCompany'} 
			},
			{
				path: 'masterUsermanagement',
				loadChildren: () => import('app/views/pages/master-user/master-user.module').then(m => m.MasterUserModule),
				canActivate: [RoleGuard], 
                data: { path: 'masterUsermanagement'} 
			},
			{
				path: 'masterPriceUnit',
				loadChildren: () => import('app/views/pages/master-price-unit/master-price-unit.module').then(m => m.MasterPriceUnitModule),
				canActivate: [RoleGuard], 
                data: { path: 'masterPriceUnit'} 
			},
			{
				path: 'masterCollector',
				loadChildren: () => import('app/views/pages/master-collector/master-collector.module').then(m => m.MasterCollectorModule),
				canActivate: [RoleGuard], 
                data: { path: 'masterCollector'} 
			},
			{
				path: 'reportMunicipality',
				loadChildren: () => import('app/views/pages/report-municipality/report-municipality.module').then(m => m.ReportMunicipalityModule),
				canActivate: [RoleGuard], 
                data: { path: 'reportMunicipality'} 
			},
			{
				path: 'masterConfigApp',
				loadChildren: () => import('app/views/pages/master-configapp/master-configapp.module').then(m => m.MasteConfigapprModule),
				canActivate: [RoleGuard], 
                data: { path: 'masterConfigApp'} 
			},
			{
				path: 'masterDashborad',
				loadChildren: () => import('app/views/pages/master-confighome/master-confighome.module').then(m => m.MasterDashboardModule),
				canActivate: [RoleGuard], 
                data: { path: 'masterDashborad'} 
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v1',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:code', component: ErrorPageComponent},
			{path: '', redirectTo: 'home', pathMatch: 'full'},
			{path: '**', redirectTo: 'home', pathMatch: 'full'}
		]
	},
	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
