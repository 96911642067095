// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	
	application: 'koomkah_amata_dev',
	linkRegist: "https://www.google.com",
	// loginPage : 'https://sso-dev.scgd.co/login/SCG/usermanagement',
	// loginPage : 'http://localhost:8072/login/usermanagement',
	// logoutPage : 'https://accessdev.scg.co.th/adfs/ls/?wa=wsignout1.0',
	// authenticationServiceRoot: 'http://localhost:8072',
	// usermanagerServiceRoot : 'http://localhost:8082/api/v1/usermanagement',
	  
	loginPage : 'https://sso-dev.scgd.co/login/koomkah_amata_dev',
	logoutPage : 'https://accessdev.scg.co.th/adfs/ls/?wa=wsignout1.0',
  
	authenticationServiceRoot: 'https://smartmanifest-dev.scgd.co/api/authen',
	authorization : "VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk",
	koomkahServiceRoot : 'https://smartmanifest-dev.scgd.co',
	
	usermanagerServiceRoot:"/api/usermanagement",
	masterdataServiceRoot:"/api/masterdata",
	operationServiceRoot:"/api/operation",
	reportServiceRoot: "/api/report"


};

