import { Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-alertModal',
    templateUrl: './alertModal.component.html',
    styleUrls: ['./alertModal.component.scss'],
    })

export class AlertModalComponent{

  constructor(
    public bsModalRef: BsModalRef,
     public translate: TranslateService
    ) {
  }
  
  type = 'success';
  messageTitle = '';
  messageBody = '';
  modalRef: BsModalRef;
  closeResult: string;

  setModal(type, title?, body?){
    this.messageTitle = '';
    this.messageBody = '';
    this.type = type;
    this.translate.get(title).subscribe(
      titleTranslate => {
        this.messageTitle = titleTranslate;
        this.translate.get(body).subscribe(
          bodyTranslate => {
            this.messageBody = bodyTranslate;
          }
        );
      }
    );
  }

  
  



   

}
