import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import {AppProfileService} from '../../_base/layout/services/appprofile.service';
import {AuthorizationService } from '../../_base/layout/services/authorization.service';
import {MenuConfigService } from '../../_base/layout/services/menu-config.service';
import { MenuConfig } from '../../../core/_config/menu.config';
// Object-Path
import * as objectPath from 'object-path';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
        private authService: AuthorizationService,
        private appProfeilService : AppProfileService,
        public router: Router) {}
        
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const path = route.data.path;
    if (!this.authService.isLoggedin() || !this.checkRole(path)) {
      this.router.navigate(['error/'+403]);
      return false;
    }
    return true;
  }

  checkRole(path){
    let menu  = objectPath.get(new MenuConfig().configs, 'header.items');
    var checkpath = "/"+path;
    if(menu){
    for(var i = 0 ;i< menu.length;i++){
      if(checkpath == menu[i]['page']){
        if(menu[i].role){
          if(menu[i].role.find( item => item.name === this.appProfeilService.role)){
            return true;
          }else{
            return false;
          }
        }else{
          return true;
        }
      }else{
        if(menu[i].submenu && this.checkRoleInMenuSub(checkpath,menu[i].submenu)){
          return true
        }
      }
    }
    }
    return false;
  }

  checkRoleInMenuSub(checkpath,item){
    for(var j = 0 ;j< item.length;j++){
      if(checkpath == item[j]['page']){
        if(item[j].role){
          if(item[j].role.find( item => item.name === this.appProfeilService.role)){
            return true;
          }else{
            return false;
          }
        }else{
          return true;
        }
      }else{
        if(item.submenu && this.checkRoleInMenuSub(checkpath,item.submenu)){
          return true
        }
      }
  }
}

 
}