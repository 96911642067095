import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceConstant } from '../../../../service.constant';
import { CommonService } from '../../layout';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'; 
import { forkJoin} from 'rxjs';
import $ from "jquery";
@Injectable()
export class AuthorizationService {

  private tokenName = "token-webPortal";
  private redirectUrlName = 'redirectUrl';
  private defaultRedirectUrl = '/';
  private storage = localStorage;
  private isLoadUserDetail = false;
  private authenPath = environment.authenticationServiceRoot;
  private serviceGetUserDetail = environment.usermanagerServiceRoot + ServiceConstant.serviceGetUserDetail;
  private serviceGetApplicationConfig = environment.masterdataServiceRoot + ServiceConstant.serviceGetApplicationConfig;
  
  private serviceRetrieveToken = this.authenPath + ServiceConstant.serviceRetrieveToken;
  private serviceForgetPassword = this.authenPath + ServiceConstant.serviceForgetPassword;
  private serviceChangPassword = this.authenPath + ServiceConstant.serviceChangPassword;
  private serviceGetcompany = environment.masterdataServiceRoot + ServiceConstant.serviceGetcompany;
  private serviceGetCollector = environment.masterdataServiceRoot + ServiceConstant.serviceGetCollector;
  private serviceLogout = this.authenPath + ServiceConstant.serviceLogout;
  snapRequestUrl = '/home'

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private commonService: CommonService) {
  }
 
  getCollector(parm?){
    return this.commonService.getNoSpinner(this.serviceGetCollector,parm);
  }
  resetPassword(parm) {
    return this.commonService.get(this.serviceForgetPassword,parm);

  }

  changPassword(parm) {
    return this.commonService.post(this.serviceChangPassword,parm);

  }

  retrieveToken(user,pass) {
    return this.commonService.retrieveToken(user,pass, this.serviceRetrieveToken);

  }

  getcompany(parm?){
    return this.commonService.getNoSpinner(this.serviceGetcompany,parm);
  }

  getUserDetail(parm) {
    return forkJoin([ 
      this.commonService.get(this.serviceGetUserDetail,parm)
      ,this.commonService.get(this.serviceGetApplicationConfig,null)
    ])
    // return this.commonService.get(this.serviceGetUserDetail,parm);
  }



  getToken() {
    let token = this.storage[this.tokenName];
    return (token) ? token : null;
  }

  isLoggedin() {
    return (this.getToken()) ? true : false;
  }

  isLoadUser() {
    return this.isLoadUserDetail
  }

  setLoadUserDetail(){
    this.isLoadUserDetail = true;
  }

  forwardToLogin(url?) {
    if (url != null && url.indexOf("saveToken") == -1)
      this.storage.setItem(this.redirectUrlName, url);
      this.storage.removeItem(this.tokenName);
      this.router.navigate(['/auth/login']);
      // location.href = this.serviceLogin;
  }


  saveTokenToStorage(access_token) {
    this.storage.setItem(this.tokenName, access_token);
  }

  logout() {
    this.snapRequestUrl = "/home"
    this.storage.removeItem(this.tokenName);
    this.storage.clear();
    this.router.navigateByUrl('/auth/login');
    this.isLoadUserDetail = false;

    // this.commonService.delete(this.serviceLogout,null)
    // .subscribe(
    //   data =>{
    //     this.storage.removeItem(this.tokenName);
    //     this.storage.clear();
    //     $('#signout_div').html('<iframe id="ifrm" (load)="loadADFSLogout()" width="100%" height="100%" src="'+environment.logoutPage+'" frameborder="0" allowFullScreen="true"></iframe>');
    //     $('#signout_div').css({
    //       'left': '0px',
    //       'top': '0px',
    //       'position': 'absolute',
    //       'z-index' : '9999',
    //       'width': window.innerWidth + 'px',
    //       'height': window.innerHeight + 'px'
    //     });
    //     document.getElementById('ifrm').onload = function() {
    //       setTimeout(() => {  
    //         location.href = environment.loginPage;
    //        }, 1000);
    //     }
    //   });


   

   }

  
  getRedirectUrl() {
    let redirectUrl = this.storage[this.redirectUrlName];
    if (redirectUrl) {
      this.storage.removeItem(this.redirectUrlName);
      return redirectUrl;
    }
    return this.defaultRedirectUrl;
  }


}

