import {Injectable} from '@angular/core';

@Injectable()
export class AppProfileService {
  public userProfile:any;
  public role:any
  public appConfig:any
  public permissions:[]
  public menuList :any;
  constructor() { }
  public load() { }

}