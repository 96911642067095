// THAI
export const locale = {
	lang: 'th',
	data: {
		MENU:{
			SUMMARY : 'สรุปรายการ',
			SUMMARY_COMPLAINT: 'รายการร้องเรียนทั้งหมด',
			REQUEST_COMPLAINT: 'แจ้งเรื่องร้องเรียน',
			REQUEST: 'ร้องเรียน',
			REPORT: 'รายงาน',
			CHECKINOUT: 'บันทึกการเก็บขยะ',
			HOME: 'หน้าหลัก',
			REPORT_MANIFEST: 'รายงานการเก็บขยะ',
			REPORT_SUMMARY: 'รายงานผลรวมการเก็บขยะ',
			REPORT_ACCOUNTING: 'รายงานสำหรับบัญชี',
			REPORT_REQUEST: 'รายงานคำร้องเรียน',
			MASTERDATA : 'ข้อมูลมาสเตอร์',
			MASTERCOMPANY : 'จัดการลูกค้า',
			USERMANAGEMENT : 'จัดการผู้ใช้',
			MASTERPRICEUNIT : 'ราคา & หน่วย',
			MASTERCOLLECTOR : 'บริษัทเก็บขยะ',
			MASTERDASHBOARD : 'ตั้งค่าหน้าหลัก',
			APPCONFIG : 'ตั้งค่าแอพ',
			REPORT_MUNICIPALITY : 'รายงานเทศบาล',
			MASTERCOMPANY_EMPLOYEE: 'ข้อมูลบริษัท'
		},
		COMMON : {
			AMATA_FACILITY : 'บริษัท อมตะ ฟาซิลิตี้ เซอร์วิส จำกัด',
			REQUIRE_FIELD : 'ฟิลด์ที่จำเป็น',
			MORE_THAN_ZERO : '! ต้องมากว่า 0',
			PLEASE_SELECT : 'กรุณาเลือก',
			PLEASE_FILL : 'กรุณากรอก ',
			LOADING : 'กำลังโหลด...',
			SEARCH : 'ค้นหา',
			RESULTS_SEARCH : 'ผลการค้นหา',
			NEXT : 'ถัดไป',
			PREVIOUS : 'ก่อนหน้า',
			EXPORT_EXCEL : 'นำออก Excel',
			OPEN_PDF : 'เปิด PDF',
			EXPORT_PDF : 'นำออก PDF',
			PRINT : 'พิมพ์',
			EXPORT_FILE : 'ตัวเลือก',
			ORDER_NUMBER : 'ลำดับ',
			DATANOTFOUND : 'ไม่พบข้อมูล',
			SHOW : 'แสดง',
			CONDITION_SEARCH : 'เงื่อนไขการค้นหา',
			DETAIL : 'รายละเอียด',
			SUBMIT_BUTTON: 'ยืนยัน',
			MONTH : 'เดือน',
			MONTH_01 : 'มกราคม',
			MONTH_02 : 'กุมภาพันธ์',
			MONTH_03 : 'มีนาคม',
			MONTH_04 : 'เมษายน',
			MONTH_05 : 'พฤษภาคม',
			MONTH_06 : 'มิถุนายน',
			MONTH_07 : 'กรกฎาคม',
			MONTH_08 : 'สิงหาคม',
			MONTH_09 : 'กันยายน',
			MONTH_10 : 'ตุลาคม',
			MONTH_11 : 'พฤศจิกายน',
			MONTH_12 : 'ธันวาคม',
			ACTION: 'แอคชั่น',
			WELCOME : 'สวัสดี',
			MYPROFILE : 'ข้อมูลส่วนตัว',
			ACCOUNT_SETTING : 'รายละเอียดการตั้งค่า',
			SIGNOUT : 'ออกจากระบบ',
			MESSAGE_SUCESS : 'ทำรายการสำเร็จ',
			STATUS_SUCESS : 'สำเร็จ',
			MESSAGE_ERROR : 'มีบางอย่างผิดพลาด',
			MESSAGE_ERROR_PLEASE_FILL_MONTH : 'กรุณากรอกรอบเดือน',
			MESSAGE_ERROR_DUPLICATE : 'ข้อมูลซ้ำในระบบ', 
			MESSAGE_ERROR_FILE_ERROR : 'ไฟล์ ไม่ถูกต้อง รองรับ ไฟล์ภาพ ,excel, pdf เท่านั้น', 
			MESSAGE_ERROR_FEE_MORETHAN_ZERO : 'ค่าบริการต้องมากกว่า 0', 
			MESSAGE_ERROR_FILE_SIZE_ERROR : 'ไฟล์มีขนาดใหญ่เกินไป',
			MESSAGE_UPDATE_ERROR : 'ไม่สามารถอัพเดทได้ กรุณาทำรายการใหม่',
			MESSAGE_MAX_DATE_90DAY : 'กรุณาเลือกช่วงวันที่ไม่เกิน 90 วัน',
			STATUS_ERROR : 'ผิดพลาด',
			MESSAGE_NO_DATA : 'ไม่มีข้อมูล',
			CLOSE_WINDOW : 'ปิดหน้าต่าง',
			VIEW_MORE : 'ดูข้อมูลเพิ่ม',
			ENABLE : 'เปิดการใช้งาน',
			DISABLED : 'ปิดการใช้งาน',
			ChooseAction : 'เลือก',
			ROLE: 'บทบาท',
			USERNAME: 'ชื่อผู้ใช้งาน',
			FIRST_NAME : 'ชื่อจริง',
			LAST_NAME : 'นามสกุล',
			PHONE_NO : 'เบอร์โทรศัพท์',
			EMAIL : 'อีเมล',
			POSITION : 'ตำแหน่ง',
			COMPANY : 'ลูกค้า',
			FACTORY_COMPANY : 'โรงงาน / บริษัท',
			COLLECTOR : 'บริษัทเก็บขยะ',
			CREATED_BY : 'สร้างโดย',
			UPDATED_DATE : 'วัน/เวลา ที่อัพเดทล่าสุด',
			ACTIVE : 'เปิด/ปิด',
			FULLNAME: 'ชื่อ-นามสกุล',
			KILOGRAM: 'กิโลกรัม',
			VIEW_DETAIL: 'ดูรายละเอียด',
			ALPHABHET_ONLY: 'เฉพาะพยัญชนะและสระ',
			ALPHABHET_EN_NUM_ONLY: 'เฉพาะตัวอักษรภาษาอังกฤษ, เลข[0-9] และ _',
			PHONE_FORMAT: 'เลขหมายโทรศัพท์ไม่ถูกต้อง',
			EMAIL_FORMAT: 'อีเมลไม่ถูกต้อง',
			PLATE_FORMAT: 'หมายเลขทะเบียนไม่ถูกต้อง',
			UNAME_DUP: 'มีชื่อผู้ใช้ในระบบแล้ว',
			EMAIL_DUP: 'มีอีเมลนี้ในระบบแล้ว',
			ADMIN_MESSAGE: 'ข้อความจากแแอดมิน',
			FILE_PIC: 'รูป/ไฟล์แนบ',
			COLLECT_TRASH: 'เก็บขยะ',
			ATLEAST_6_PASS: 'ต้องการอย่างน้อย 6 ตัวอักษร',
			CLICK : 'คลิก',
			MESSAGE_ERROR_APPROVED : 'รายการนี้ถูกอนุมัติแล้ว',
			MESSAGE_ERROR_CANCELLED : 'รายการนี้ถูกยกเลิกแล้ว',
			LESS_THAN_ZERO : 'มากกว่าหรือเท่ากับ 0 เท่านั้น',
			OPENED_REQ_TIME : 'เวลาร้องเรียน',
			ACK_REQ_TIME : 'เวลารับเรื่อง',
			RESOLVED_REQ_TIME : 'เวลาได้รับการแก้ไข',
			COMP_DUP : 'รหัสลูกค้ามีในระบบแล้ว',
			FROM : 'จาก',
			COMPLAINT_TO : 'ร้องเรียนถึง',
			IMAGE_ATTCH : 'แนบภาพ',
			COMPANY_CODE : 'รหัสลูกค้า'
		},
		LANGUAGE :{
			THAI : 'ไทย',
			ENG  : 'อังกฤษ'
		},
		AUTH: {
			MESSAGE:{
				RESET_PASSWORD_SUCESS : 'กรุณาตรวจสอบ อีเมล์',
				EMAIL_NOT_REGISTER : 'ชื่อผู้ใช้ หรือ อีเมล ไม่ถูกต้อง',
				PASSWORD_NOT_MATCH : 'พาสเวิร์ดไม่ตรงกัน',
				CHANGE_PASSWORD_SUCESS : 'เปลี่ยนพาสเวิร์ดเรียบร้อย',
				AUTH_FAIL : 'ชื่อผู้ใช้ หรือ พาสเวิร์ด ไม่ถูกต้อง',
				SESSION_EXPIRED : 'Session หมดอายุ'
			},
			GENERAL: {
				OR: 'หรือ',
				SUBMIT_BUTTON: 'ยืนยัน',
				NO_ACCOUNT: 'คุณยังไม่มี บัญชีใช้งานใช่หรือไม่',
				SIGNUP_BUTTON: 'สมัครสมาชิก',
				FORGOT_BUTTON: 'ลืมรหัสผ่าน',
				BACK_BUTTON: 'กลับ',
				PRIVACY: 'ความเป็นส่วนตัว',
				LEGAL: 'กฎหมาย',
				CONTACT: 'ติดต่อ',
				REGIST: 'ลงทะเบียนใช้งาน'
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'เข้าสู่ระบบ',
			},
			FORGOT: {
				TITLE: 'ลืมรหัสผ่านใช่หรือไม่',
				DESC: 'กรุณาเข้าอีเมลของคุณ เพื่อแก้ไขรหัสผ่านใหม่',
				SUCCESS: 'ตั้งรหัสผ่านสำเร็จ'
			},
			REGISTER: {
				TITLE: 'สมัครสมาชิก',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'สมัครสมาชิกเรียบร้อย'
			},
			INPUT: {
				EMAIL: 'อีเมล',
				PASSWORD: 'รหัสผ่าน',
				CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
				USERNAME: 'ชื่อผู้ใช้งาน'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			}
		},
		Activity : 'ตัวเลือก',
		CANCELLED : "ยกเลิก",
		PENDING : "รออนุมัติ", 
		APPROVED:  "อนุมัติ",
		REJECTED : "ถูกปฏิเสธ",
		WasteType : 'ชนิดขยะ',
		Company : 'ลูกค้า',
		ReportType : 'ประเภทรายงาน',
		Request: 'ร้องเรียน',
		Daily : 'ประจำวัน',
		Monthly : 'ประจำเดือน',
		Yearly : 'ประจำปี',
		SubDistrict : 'ตำบล/เขต',
		District : 'อำเภอ/แขวง',
		Province : 'จังหวัด',
		Zipcode : 'รหัสไปรษณีย์',
		Date : 'วันที่',
		Qty :  'ปริมาณ',
		Amount :  'จำนวน',
		Unit : 'หน่วย',
		Collector : 'เจ้าหน้าที่เก็บขยะ',
		Employee : 'เจ้าหน้าที่โรงงาน',
		Check_In_Time: 'เวลาเข้า',
		Check_Out_Time: 'เวลาออก',
		CompanyName : 'ชื่อลูกค้า',
		AddressCompany: 'ที่ตั้ง',
		BTN_Create: 'สร้าง',
		BTN_Check_In: 'เช็คอิน',
		BTN_Check_Out: 'เช็คเอ้าท์',
		BTN_Clear: 'ล้างข้อมูล',
		BTN_Back: 'ย้อนกลับ',
		BTN_Confirm: 'ยืนยัน',
		BTN_Next: 'ต่อไป',
		BTN_Addwaste : 'เพิ่ม',
		BTN_Cancel : 'ยกเลิก',
		BTN_BEGIN_TRANSACATION : 'เริ่มเก็บขยะใหม่',
		BTN_Approve: 'อนุมัติรายการ',
		BTN_Reject: 'ปฏิเสธรายการ',
		BTN_Edit: 'แก้ไข',
		BTN_Complaint: 'ร้องเรียน',
		BTN_Delete : 'ลบ',
		BTN_Save : 'บันทึก',
		BTN_Add : 'เพิ่ม',
		BTN_Upload_File : 'แนบไฟล์',
		File : 'ไฟล์ , รูป',
		ForPlant : 'สำหรับเจ้าหน้าที่โรงงาน',
		Total : 'รวม',
		Change : 'เปลี่ยน',
		Confirm_transaction : 'ยืนยันรายการ',
		Please_Confirm_transaction : 'คุณได้ตรวจสอบและต้องการยืนยันการทำรายการ ?',
		Please_Cancel_transaction : 'คุณยืนยัน การยกเลิกรายการดังกล่าว?',
		Please_Approve_transaction : 'คุณได้ตรวจสอบและต้องการยืนยันรายการดังกล่าว?',
		Please_Reject_transaction : 'คุณยืนยัน การปฏิเสธรายการดังกล่าว?',
		Remark : 'ข้อเสนอ/คำอธิบาย',
		Request_Type : 'ประเภทการร้องเรียน',
		Title : 'หัวข้อ',
		Detail : 'รายละเอียด',
		Status : 'สถานะ',
		Code : 'รหัส',
		Fee : 'อัตราค่าบริการ / หน่วย',
		Total_before_vat : 'จำนวนเงินก่อน VAT',
		Total_before_discount : 'จำนวนเงินก่อนลดราคา',
		Total_discount : 'ส่วนลด',
		Discount_per_unit : 'ส่วนลด/หน่วย',
		Total_vat : 'จำนวน VAT' ,
		Opened : "เปิด" ,
		Acknowledged : "รับเรื่อง" ,
		Resolved : "ได้รับการแก้ไขแล้ว" ,
		Requester : "ผู้ร้องเรียน",
		Approved_by : 'อนุมัติโดย',
		Acc_code : 'Acc Code',
		Create_Company : 'สร้างลูกค้าใหม่',
		Create_Collector : 'สร้างบริษัทเก็บขยะ',
		UnitForBilling : 'หน่วยในการคิดเงิน',
		UnitCollection : 'หน่วยในการเก็บ',
		SettingCollector :'ตั้งค่าการเก็บขยะ',
		UpdateDate : 'วันที่อัพเดท',
		UpdateBy : 'อัพเดทโดย',
		CreatedDate :'วันที่สร้าง',
		CreatedBy : 'สร้างโดย',
		User : 'ผู้ใช้',
		CompanyType : 'ประเภทลูกค้า',
		Plate_No : 'ทะเบียนรถ',
		UnitConvertion : 'แปลงหน่วยในการเก็บ',
		Approve_transaction: 'ยืนยันการอนุมัติ',
		Driver : 'เจ้าหน้าที่รับขยะ',
		Customer : 'เจ้าหน้าที่โรงงาน',
		CUSTOMER : 'เจ้าหน้าที่โรงงาน',
		Customer_code : 'รหัสลูกค้า',
		Customer_name : 'ชื่อลูกค้า',
		General : 'ข้อมูลทั่วไป',
		DetailWaste : 'ข้อมูลเกี่ยวกับปริมาณขยะ',
		Report_manifest_title : 'แบบฟอร์มบันทึกปริมาณขยะจากโรงงานอุตสาหกรรม',
		Report_summary_title : 'สรุปผลรวมปริมาณขยะจากโรงงานอุตสาหกรรม',
		Report_municipality_title : 'สรุปปริมาณขยะที่จัดเก็บ รวบรวม และคัดแยก โดยบริษัท อมตะ ฟาซิลิตี้ เซอร์วิส จำกัด',
		Report_request_title : 'แบบฟอร์มสรุปรายการร้องเรียน',
		Amata_city : 'นิคมอุตสาหกรรมอมตะซิตี้ ชลบุรี',
		ReportWaste : 'รายงานการเก็บขยะ',
		ReportMunicipality : 'สรุปปริมาณขยะที่จัดเก็บประจำเขตพื้นที่',
		Municipality : 'เขตพื้นที่',
		Create_User : 'สร้างผู้ใช้',
		AccountedForCapacity :'คิดเป็นความจุ',
		ADMIN: 'แอดมิน',
		WT_COLLECTOR: 'เจ้าหน้าที่เก็บขยะ',
		WT_OFFICE: 'เจ้าหน้าที่ธุรการ',
		AFS: 'อมตะแอดมิน',
		NOT_ASSIGN: 'ไม่ได้ระบุ',
		Billing_date : 'วันที่ตัดรอบบิล',
		Local : 'ภาษาท้องถิ่น',
		EveryDay : 'ทุกๆวันที่ ',
		EveryLastDay_month : 'ทุกๆ สิ้นเดือน',
		DASHBOARD_NEW : 'ข่าวสาร / ประชาสัมพันธ์',
		GenaratePDF_ALL_CUSTOMER : 'สร้างเอกสารทุกลูกค้า',
		GenaratePDF_ALL_MUNICIPALITY  : 'สร้างเอกสารทุกเทศบาล',
		GenarateExcel_ALL_CUSTOMER : 'สร้าง Excel ทุกลูกค้า',
		WASTE_COLLECTION : 'การเก็บขยะ',
		IF_EDIT_OPEN_REQUEST : '** หมายเหตุ : หากต้องการแก้ไข รายละเอียดข้อมูล รบกวนสร้างคำร้องเรียน',
		TITLE_EDIT_DATA_CUSTOMER_COMPLAINT : 'แก้ไขข้อมูล ลูกค้า [ {{code}} ] {{name}}',
		TITLE_EDIT_DATA_COLLECTOR_COMPLAINT : 'แก้ไขข้อมูล บริษัทเก็บขยะ [ {{code}} ] {{name}}',
		IF_AFTER_DATE_NEXT_PERIOD :'** ข้อมูลหลังวันที่ {{date}} จะถือเป็นข้อมูลเดือนถัดไปตามรอบบิล',
		Image : "ภาพ",
		Link : "ลิงก์",
		News : "ข่าวสาร",
		SlideNews : "ข่าวสไลด์",
		Summary_collect_trash : "ผลรวมเก็บขยะ",
		UpdatedDate : "วันที่อัพเดทล่าสุด",
		transaction : "รายการ",
		KG : "กิโลกรัม",
		BK : "ถัง",
		BG : "ถุง",
		ITEM_CANCELLED: "รายการที่ยกเลิก",
		ITEM_PENDING: "รายการที่รออนุมัติ",
		ITEM_APPROVED: "รายการที่อนุมัติแล้ว"
	}
};
