import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'customDate' })
export class customDatePipe implements PipeTransform {
  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  public storage = localStorage;
  transform(date: Date | string, format: string = 'yyyy-MM-dd'): string {
    // date = new Date(date);  // if orginal type was a string
    if(date != null){
      if(this.storage['language'] == 'th'){
        let ThaiDay = ['อาทิตย์','จันทร์','อังคาร','พุธ','พฤหัสบดี','ศุกร์','เสาร์']
        let shortThaiMonth = [
            'ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.',
            'ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'
            ];  
        let longThaiMonth = [
            'มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน',
            'กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'
            ];   
        let inputDate=new Date(date);
        let dataDate = [
            inputDate.getDay(),inputDate.getDate(),inputDate.getMonth(),inputDate.getFullYear(),inputDate.getHours(),inputDate.getMinutes()
            ];
        let outputDateFull = [
            'วัน '+ThaiDay[dataDate[0]],
            'ที่ '+dataDate[1],
            'เดือน '+longThaiMonth[dataDate[2]],
            'พ.ศ. '+(dataDate[3]+543)
        ];
        let outputDateShort = [
            dataDate[1],
            shortThaiMonth[dataDate[2]],
            dataDate[3]+543
        ];
        let outputDateMedium = [
            dataDate[1],
            longThaiMonth[dataDate[2]],
            dataDate[3]+543
        ];    
        let outputDateTimeFull = [
          'วัน '+ThaiDay[dataDate[0]],
          'ที่ '+dataDate[1],
          'เดือน '+longThaiMonth[dataDate[2]],
          'พ.ศ. '+(dataDate[3]+543),
          'เวลา '+dataDate[4]+':'+dataDate[5]+' น.'
      ];
      let outputDateTimeShort = [
          dataDate[1],
          shortThaiMonth[dataDate[2]],
          dataDate[3]+543,
          dataDate[4]+':'+dataDate[5]+' น.'
      ];
      let outputDateTimeMedium = [
          dataDate[1],
          longThaiMonth[dataDate[2]],
          dataDate[3]+543,
          dataDate[4]+':'+dataDate[5]+' น.'
      ];    

      let outputTime = [
        dataDate[4]+':'+dataDate[5]+' น.'
      ];   
        let returnDate:string;
        returnDate = outputDateMedium.join(" ");
        if(format=='longDate'){
            returnDate = outputDateFull.join(" ");
        }else if(format=='mediumDate'){
            returnDate = outputDateMedium.join(" ");
        }else if(format=='shortDate'){
            returnDate = outputDateShort.join(" ");
        }else if(format=='long'){
          returnDate = outputDateTimeFull.join(" ");
        }else if(format=='medium'){
            returnDate = outputDateTimeMedium.join(" ");
        }else if(format=='short'){
            returnDate = outputDateTimeShort.join(" ");
        }else if(format=='shortTime'){
          returnDate = outputTime.join(" ");
        }else{
          return new DatePipe('en-US').transform(date, format);
        }    
        return returnDate;
      }else{
        return new DatePipe('en-US').transform(date, format);
      }
    }
  }
}